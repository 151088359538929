import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';

@Injectable({
  providedIn: 'root'
})
export class HttpInjectableStoreDataService{

  constructor(public http: HttpClient,public httpUrlGenerator:HttpUrlGenerator){
  }
}
